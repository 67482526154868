import React from 'react'

export const Pricing = () => {
  return (
    <section className="pricing section-padding">
    <div className="container">
        <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
                <h2 className="section-title">
                    Pricing
                </h2>
                <p className="section-para">
                    Surrogacy in Ghana is cheaper than almost anywhere else in the world. Our basic package for the least complex journeys begins at $29,000. The exact cost of your journey will depend on the specifics of your situation. See below for a sample cost breakdown:
                </p>
            </div>

            <div className="col-md-5">
                <div className="image">
                    <img src="/assets/images/pricing.jpg" alt="" />
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
