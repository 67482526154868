import React from 'react'

export const Footer = () => {
  return (
    <footer className="section-padding footer pb-0">
        <div className="container">
            <h2 className="section-title text-center">
                Contact Us
            </h2>
            <p className="section-para text-center">
                We're here to help and answer any questions you may have.
            </p>
            <div className="col-md-8 px-0 mx-auto mt-5">
                <ul className="contact-inf">
                    <li><a href="mailto:info@goldcoastsurrogacy.com"><img src="/assets/images/email.svg" alt="" /> info@goldcoastsurrogacy.com</a></li>
                    <li><a href="#"><img src="/assets/images/call.svg" alt="" /> +233 50 669 4427</a></li>
                    
                </ul>
                <ul className="social-media">
                    <li><a href="#"><img src="/assets/images/facebook.svg" alt="" /></a></li>
                    <li><a href="#"><img src="/assets/images/instagram.png" alt="" /></a></li>
                    <li><a href="#"><img src="/assets/images/x.svg" alt="" /></a></li>
                    <li><a href="#"><img src="/assets/images/youtube.svg" alt="" /></a></li>
                    <li><a href="#"><img src="/assets/images/tiktok.svg" alt="" /></a></li>
                    <li><a href="#"><img src="/assets/images/whatapp.svg" alt="" /></a></li>
                </ul>
            </div>
            <div className="footer-lower">
                <p className="mb-0">© All rights reserved Gold Coast Surrogacy 2024.</p>
                <ul className="mb-0"><li><a href="#">Privacy Policy</a></li><li><a href="#">Terms & Conditions</a></li></ul>
            </div>
        </div>
    </footer>
  )
}
