import React from 'react'

export const OurProcess = () => {
  return (
    <section className="our-process section-padding">
            <div className="container text-center mb-bt-100">
                <div className="col-md-6 px-0 mx-auto">
                    <h2 className="section-title mb-4">Our Process</h2>
                   
                </div>
            </div>

            <div className="container">
                <div className="col-md-11 mx-auto px-0">
                    <div className="list-cony">
                        <ul>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       01.
                                    </div>
                                    <h4>Consultation</h4>
                                    <p>Learn about the services Gold Coast Surrogacy has to offer, select the ones that work best for you, and sign an agreement for services.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       02.
                                    </div>
                                    <h4>Create or Ship Embryos</h4>
                                    <p>Create embryos in Ghana (with an egg and/or sperm donor if needed), or ship existing embryos to Ghana.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       03.
                                    </div>
                                    <h4>Surrogate Matching</h4>
                                    <p>Select the right surrogate mother from our pool that has been pre-screened based on their health and pregnancy history, psychological assessment, and background checks.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       04.
                                    </div>
                                    <h4>Surrogacy Agreement</h4>
                                    <p>Complete a legal, written contract with the surrogate that states the rights and obligations of all parties.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       05.
                                    </div>
                                    <h4>Embryo Transfer</h4>
                                    <p>The surrogate mother will receive a medication and monitoring protocol to prepare for the embryo transfer; once the embryo is transferred, a pregnancy test will be administered after 10 days, and an ultrasound after 6 weeks will provide confirmation of a heartbeat.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       06.
                                    </div>
                                    <h4>Surrogate Pregnancy</h4>
                                    <p>Gold Coast Surrogacy will take full care of your surrogate; this includes providing housing, medical care, a specialized diet, prenatal vitamins, and anything else needed to make sure the surrogate is happy and healthy.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       07.
                                    </div>
                                    <h4>Pre-Parental Order</h4>
                                    <p>Within 12 weeks of the embryo transfer, the application for the Pre-Parental Order is filed at the High Court; once approved, this document allows the intended parent(s) to be named officially on record as the parent(s) of the child.</p>
                                </div>
                            </li>
                            <li>
                                <div className="point-container">
                                    <div className="point-number">
                                       08.
                                    </div>
                                    <h4>Birth & Parenthood</h4>
                                    <p>Your journey with Gold Coast Surrogacy doesn’t stop at birth. We’ll be there to help you through the early days of parenthood. This includes helping you secure accommodations, nannys, formula, clothes, diapers, and whatever else you might need!</p>
                                </div>
                            </li>
                          </ul>
                    </div>
                 
                </div>
            </div>
        </section>
  )
}
