import { useEffect, useRef, useState } from 'react';
import './App.css';
import { About } from './components/About';
import { Banner } from './components/Banner';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { OurProcess } from './components/OurProcess';
import { Pricing } from './components/Pricing';
import "./accordion.css";
function App() {
// Create references for each component
const aboutRef = useRef(null);
const processRef = useRef(null);
const pricingRef = useRef(null);
const headerRef = useRef(null);
const refs = useRef([]);
// Scroll functions
const scrollToSection = (sectionRef) => {
  
  //sectionRef.current.scrollIntoView({ top: sectionRef.current.offsetTop - 150, behavior: 'smooth'});
  const offset = 100; // Adjust based on your header height
  const top = sectionRef.current.getBoundingClientRect().top + window.scrollY  - offset;
  window.scrollTo({ top, behavior: 'smooth' });
};
const [active, setActive] = useState(1);

const toggle = (index) => {
  setActive(active === index ? null : index);
};

useEffect(()=> {
  setActive(null);
}, []);


  return (
      <main className="App">
        <Header  ref={headerRef} onScrollToAbout={() => scrollToSection(aboutRef)}
        onScrollToProcess={() => scrollToSection(processRef)}
        onScrollToPricing={() => scrollToSection(pricingRef)}
        />
          <Banner />
        
        <div ref={aboutRef}>
          <About />
        </div>

        <div ref={processRef}>
          <OurProcess />
        </div>

        <div ref={pricingRef}>
          <Pricing />
        </div>

        <section className="pricing-table section-padding pt-0">
      <div className="container">
        <h3 className="text-center pb-5">Full Service Surrogacy Journey Estimate (in USD)</h3>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={`accordion-button ${active === 1 ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne" 
                onClick={() => toggle(1)}
              >
                <div className="d-block w95">
                  <div className="d-flex justify-content-between">
                    <span>Embryo Creation Expenses</span> <span>$3,000</span>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseOne"
              ref={(el) => (refs.current[1] = el)}
              className="accordion-collapse"
              style={{
                maxHeight: active === 1 ? refs.current[1]?.scrollHeight : 0,
              }}
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>In vitro fertilization (IVF)</li>
                  <li>Embryo storage for 6 months</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={`accordion-button ${active === 2 ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                onClick={() => toggle(2)}

              >
                <div className="d-block w95">
                  <div className="d-flex justify-content-between">
                    <span>Surrogacy Agency Fee (Single Pregnancy)</span> <span>$25,000</span>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseTwo"
              ref={(el) => (refs.current[2] = el)}
              className="accordion-collapse"
              style={{
                maxHeight: active === 2 ? refs.current[2]?.scrollHeight : 0,
              }}
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>Vetting and matching of surrogate</li>
                  <li>Medical screening of surrogate</li>
                  <li>Surrogacy contract legal fees</li>
                  <li>Embryo transfer (up to 3 attempts)</li>
                  <li>Surrogate compensation</li>
                  <li>Surrogate housing & food</li>
                  <li>Surrogate doctor appointments</li>
                  <li>Surrogate therapist</li>
                  <li>Surrogate prenatals</li>
                  <li>Pre-parental order</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={`accordion-button ${active === 3 ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                onClick={() => toggle(3)}
              >
                <div className="d-block w95">
                  <div className="d-flex justify-content-between">
                    <span>Child Birth Hospital Expenses</span> <span>$1,000</span>
                  </div>
                </div>
              </button>
            </h2>
            <div
              id="collapseThree"
              ref={(el) => (refs.current[3] = el)}
              className="accordion-collapse"
              style={{
                maxHeight: active === 3 ? refs.current[3]?.scrollHeight : 0,
              }}
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <li>Delivery (natural or c-section)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="total">
          <span>Total Cost</span>
          <span>$29,000</span>
        </div>
        <p className="mt-4">
          Estimate does not include costs for intended parents (travel, housing, visas), or travel documents for the baby.
        </p>

        <div className="col-md-3 mx-auto mt-5">
          <a href="mailto:info@goldcoastsurrogacy.com?subject=Request%20Information%20About%20Surrogacy%20in%20Ghana" className="btn btn-primary btn-block py-3">
            Request more Information
          </a>
        </div>
      </div>
    </section>

        <Footer />
    </main>
  );
}

export default App;
