import React from 'react'

export const Banner = () => {
  return (
    <section className="hero-banner">
        <div className="container">
            <div className="banner-text">
                <h1>Begin your journey to parenthood in Ghana with us</h1>
                <p>Gold Coast Surrogacy is a full-service agency providing families all over the world with the highest level of care and service.</p>
            </div>
        </div>
    </section>
  )
}
