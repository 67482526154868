import React from 'react'

export const About = () => {
  return (
    <section className="about-us section-padding">
            <div className="container text-center mb-bt-100">
                <div className="col-md-6 px-0 mx-auto">
                    <h2 className="section-title mb-4">About Us</h2>
                    <p className="section-para">Gold Coast Surrogacy is the premier surrogacy agency in Ghana. We’re passionate about helping people all over the world build families. We operate based on our three core values:</p>
                </div>
            </div>

            <div className="container container-02">
                <div className="col-md-10 px-0 mx-auto">
                  <div className="row justify-content-between align-items-center mb-bt-100">
                    <div className="col-md-5 image-col">
                        <div className="image">
                            <img src="/assets/images/positive-outcome.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                      <h3 className="mb-4">Positive Outcomes</h3>
                      <p className="min-para">The team of professionals at Gold Coast Surrogacy have extensive backgrounds in medicine, law, social work, finance, and management. We are all very experienced in the different aspects of the surrogacy journey and we utilize our collective experience to deliver the best results.</p>
                    </div>
                  </div>


                <div className="row justify-content-between align-items-center mb-bt-100">
                  <div className="col-md-5 order-md-2 col-md-5 image-col image-col-right">
                      <div className="image">
                          <img src="/assets/images/exceptional-service.jpg" alt="" />
                      </div>
                  </div>
                  <div className="col-md-6 order-md-1">
                  <h3 className="mb-4">Exceptional Service</h3>
                  <p className="min-para">Our leadership team is comprised of former intended parents and former surrogates who understand the journey on a personal level. Building trust is important, and the best way that we achieve that is by being extremely responsive, operating in a transparent manner, and being open in our communication.</p>
                  </div>
                </div>



                <div className="row justify-content-between align-items-center mb-bt-100">
                  <div className="col-md-5 image-col image-col-end">
                      <div className="image ">
                          <img src="/assets/images/ethical-practice.jpg" alt="" />
                      </div>
                  </div>
                  <div className="col-md-6">
                  <h3 className="mb-4">Ethical Practice</h3>
                  <p className="min-para">Surrogacy in Ghana is 100% legal, safe, and affordable. The affordability is driven by purchasing power differences between Ghana and other parts of the world. We ensure that our surrogates are treated very well and compensated very generously for the invaluable gift they are giving. It’s important to us that everyone involved in the surrogacy journey comes away having had an extremely positive experience.</p>
                  </div>
                </div>
              </div>
            </div>
        </section>
  )
}
