import { React, useRef } from 'react'
import { Link } from 'react-router-dom';
export const Header = ({ onScrollToAbout, onScrollToProcess, onScrollToPricing }) => {
   
  return (
    <header className="header">
            <div className="container">
                <Link to="#" className="header-logo"><img src='./assets/images/logo.png' width="116" alt="Gold Coast Surrogacy" /></Link>
                <div className="nav">
                    <ul>
                        <li>
                            <Link href='javascript:void(0);' onClick={onScrollToAbout}>About Us</Link>
                        </li>
                        <li>
                            <Link href='javascript:void(0);' onClick={onScrollToProcess}>Our Process</Link>
                        </li>
                        <li>
                            <Link href='javascript:void(0);' onClick={onScrollToPricing}>Pricing</Link>
                        </li>
                    </ul>
                </div>
                <Link className="btn btn-primary"
                    to='#'
                    onClick={(e) => {
                        window.location.href = 'mailto:info@goldcoastsurrogacy.com?subject=Request Information About Surrogacy in Ghana';
                        e.preventDefault();
                    }}
                >
                   Request Information
                </Link>
            </div>
    </header>
  )
}
